import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import Home from "../pages/Home";
import Stories from "../pages/Stories";
import Ads from "../pages/Ads";
import Parceria from "../pages/Parceria";
import Direct from "../pages/Direct";

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/ads" component={Ads} />
            <Route path="/direct" component={Direct} />
            <Route path="/stories" component={Stories} />
            <Route path="/parceria" component={Parceria} />
        </Switch>
    );
};

export default Routes;
